import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CanalboxPaymentHomepageComponent } from './components/canalbox-payment-homepage/canalbox-payment-homepage.component';
import { CanalboxPaymentCheckoutComponent } from './components/canalbox-payment-checkout/canalbox-payment-checkout.component';
import { FooterComponent } from './components/footer/footer.component';
import { CanalboxPaymentInitializationComponent } from './components/canalbox-payment-initialization/canalbox-payment-initialization.component';
import { CountdownModule } from 'ngx-countdown';
import { IntermediatePageComponent } from './components/intermediate-page/intermediate-page.component';
import { IntermediatePageReturnurlComponent } from './components/intermediate-page-returnurl/intermediate-page-returnurl.component';
import { TermsComponent } from './components/terms/terms.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxSmartModalModule } from 'ngx-smart-modal';

@NgModule({
  declarations: [
    AppComponent,
    CanalboxPaymentHomepageComponent,
    CanalboxPaymentCheckoutComponent,
    FooterComponent,
    CanalboxPaymentInitializationComponent,
    IntermediatePageComponent,
    IntermediatePageReturnurlComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    NgxSmartModalModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CountdownModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
 ],
  bootstrap: [AppComponent]
})
export class AppModule { }
