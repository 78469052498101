<footer class="responsive-setter">
  <p
    class="xs:text-center py-4 sm:border-b-2 pb-2 border-gray-300 text-gray-500"
  >
    Nous acceptons
  </p>
  <div class="flex flex-row justify-between">
    <div class="flex flex-row justify-start">
      <p class="uppercase text-gray-400 my-auto text-sm xs:hidden">credit</p>
      <img
        class="img"
        src="../../../../assets/img/visacard-logo-gray.png"
        alt=""
      />
      <img
        class="img"
        src="../../../../assets/img/mastercard-logo-gray.png"
        alt=""
      />
    </div>
    <div class="flex flex-row justify-start">
      <p class="uppercase text-gray-400 mx-auto my-auto text-sm xs:hidden">
        mobile
      </p>
      <img class="img" src="../../../../assets/img/om-logo-gray.png" alt="" />
      <img class="img" src="../../../../assets/img/mtn-logo-gray.png" alt="" />
      <img class="img" src="../../../../assets/img/moov-logo-gray.png" alt="" />
    </div>
  </div>
</footer>
