import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanalboxPaymentCheckoutComponent } from './components/canalbox-payment-checkout/canalbox-payment-checkout.component';
import { CanalboxPaymentHomepageComponent } from './components/canalbox-payment-homepage/canalbox-payment-homepage.component';
import { CanalboxPaymentInitializationComponent } from './components/canalbox-payment-initialization/canalbox-payment-initialization.component';
import { IntermediatePageReturnurlComponent } from './components/intermediate-page-returnurl/intermediate-page-returnurl.component';
import { IntermediatePageComponent } from './components/intermediate-page/intermediate-page.component';
import { TermsComponent } from './components/terms/terms.component';
import { RouteGuard } from './guards/route.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'gva/pay',
    pathMatch: 'full',
  },
  { path: 'gva/pay', component: CanalboxPaymentInitializationComponent },
  { path: 'gva/checkout', component: CanalboxPaymentCheckoutComponent, canActivate: [RouteGuard]},
  { path: 'gva/renew/:id', component: CanalboxPaymentHomepageComponent },
  { path: 'gva/intermediate-page', component: IntermediatePageComponent },
  {path: 'gva/intermediate-page-returnurl', component: IntermediatePageReturnurlComponent },
  {path: 'gva/terms', component: TermsComponent },
  {
    path:'**',
    redirectTo:'gva/pay',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
