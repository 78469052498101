<div class="xs:flex flex-col gap-4 sm:grid grid-cols-12 h-4/6 mt-8 md:gap-x-6">
  <div class="col-span-3 bg-white shadow-md h-48 flex flex-col justify-center">
    <img
      class="xs:object-scale-down sm:object-scale-down h-24 my-auto"
      src="../../../assets/img/canalbox-logo.png"
      alt=""
    />
    <p
      class="
        xs:pb-4
        text-center
        uppercase
        sm:uppercase
        mx-auto
        my-auto
        font-bold
        text-gray-900
      "
    >
      CANALBOX cote d'ivoire
      <span class="xs:font-normal block sm:hidden"
        >Ligne d'assistance clientèle: <span class="font-bold">1341</span></span
      >
    </p>
  </div>
  <div class="sm:col-span-9 bg-white shadow-md">
    <p class="xs:text-center pt-5 sm:pt-6 pl-4 pb-2 text-blue-800 font-bold">
      Réabonnez-vous à CANALBOX
    </p>
    <p class="xs:pt-2 px-4 sm:pl-5">
      L’internet illimité à très haut débit via la fibre optique.
    </p>
    <!-- Start of payment form -->
    <form
      class="my-4 font-light"
      #gvaForm="ngForm"
      (ngSubmit)="onSubmit(gvaForm)"
    >
      <div class="sm:grid grid-cols-2 gap-x-12">
        <div class="flex flex-col px-4 xs:my-4">
          <label for="lastName" class="mb-2">
            <span class="text-red-600">*</span> Nom de famille :</label
          >
          <input
            [(ngModel)]="lastName"
            name="lastName"
            #lastNameInput="ngModel"
            class="rounded-md"
            type="text"
            placeholder="Votre nom"
            required
            id="lastName"
            value="{{ lastName }}"
          />
          <div>
            <p
              class="text-red-700"
              *ngIf="lastNameInput.touched && !lastNameInput.valid"
            >
              Votre nom de famille est requis
            </p>
          </div>
        </div>
        <div class="flex flex-col px-4 xs:my-4">
          <label for="firstName" class="mb-2">
            <span class="text-red-600">*</span> Prénom :</label
          >
          <input
            [(ngModel)]="firstName"
            name="firstName"
            #firstNameInput="ngModel"
            class="rounded-md"
            type="text"
            placeholder="Votre prénom"
            required
            id="firstName"
            value="{{ firstName }}"
          />
          <div>
            <p
              class="text-red-700"
              *ngIf="firstNameInput.touched && !firstNameInput.valid"
            >
              Votre prénom est requis
            </p>
          </div>
        </div>
      </div>
      <div class="sm:grid grid-cols-2 gap-x-12 mt-6">
        <div class="flex flex-col px-4 xs:my-4">
          <label for="email" class="mb-2">
            <span class="text-red-600">*</span> E-mail:</label
          >
          <input
            [(ngModel)]="email"
            email
            name="email"
            #emailInput="ngModel"
            class="rounded-md"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            placeholder="username@example.com"
            required
            id="email"
            value="{{ email }}"
          />
          <div>
            <p
              class="text-red-700"
              *ngIf="emailInput.touched && !emailInput.valid"
            >
              Entrer une adresse mail correcte
            </p>
          </div>
        </div>
        <div class="flex flex-col px-4 xs:my-4">
          <label for="address" class="mb-2">
            <span class="text-red-600">*</span> Adresse:</label
          >
          <input
            [(ngModel)]="address"
            name="address"
            #addressInput="ngModel"
            class="rounded-md"
            type="text"
            placeholder="Votre adresse"
            required
            id="address"
            value="{{ address }}"
          />
          <div>
            <p
              class="text-red-700"
              *ngIf="addressInput.touched && !addressInput.valid"
            >
              Votre adresse est requise
            </p>
          </div>
        </div>
      </div>
      <div class="sm:grid grid-cols-2 gap-x-12 mt-6">
        <div class="flex flex-col px-4 xs:my-4">
          <label for="city" class="mb-2">
            <span class="text-red-600">*</span> Ville:</label
          >
          <input
            [(ngModel)]="city"
            name="city"
            #cityInput="ngModel"
            class="rounded-md"
            type="text"
            placeholder="Votre ville"
            required
            id="city"
            value="{{ city }}"
          />
          <div>
            <p
              class="text-red-700"
              *ngIf="cityInput.touched && !cityInput.valid"
            >
              Votre ville est requise
            </p>
          </div>
        </div>
        <div class="flex flex-col px-4 xs:my-4">
          <label for="country" class="mb-2">
            <span class="text-red-600">*</span> Pays:</label
          >
          <select class="rounded-md" name="country" id="country" required>
            <option value="Cote DIvoire">Cote D'Ivoire</option>
          </select>
        </div>
      </div>
      <div class="sm:grid grid-cols-2 gap-x-12 mt-6">
        <div class="flex flex-col px-4 xs:my-4">
          <label for="phone" class="mb-2">
            <span class="text-red-600">*</span> Numéro téléphone-
            <span class="text-red-500 italic"
              >Indiquer votre numéro Mobile Money pour le paiement mobile</span
            ></label
          >
          <input
            [(ngModel)]="phoneNumber"
            name="phone"
            #phoneInput="ngModel"
            class="rounded-md"
            type="text"
            pattern="[0-9]{10}"
            maxlength="10"
            placeholder="Votre numéro"
            required
            id="phone"
            value="{{ phoneNumber }}"
          />
          <div>
            <p
              class="text-red-700"
              *ngIf="phoneInput.touched && !phoneInput.valid"
            >
              Veuillez entrer un numéro valide (10 chiffres)
            </p>
          </div>
        </div>
        <div class="flex flex-col px-4 xs:my-4">
          <label for="routerNumber" class="mb-2">
            <span class="text-red-600">*</span> Numéro routeur:
            <span class="text-red-500 italic"
              >votre numéro de routeur devra commencer par ALCL au lieu de 222</span
            >
          </label>

          <input
            [(ngModel)]="routerNumber"
            name="routerNumber"
            #router="ngModel"
            class="rounded-md"
            type="text"
            pattern="[A][L][C][L][0-9A-Z]{8}"
            maxlength="12"
            placeholder="ALCLXXXXXXXX"
            required
            id="routerNumber"
            value="{{ routerNumber }}"
          />
          <div>
            <p class="text-red-700" *ngIf="router.touched && !router.valid">
              Veuillez saisir les 8 caractères (majuscules) de votre numéro de routeur
              précedés de ALCL au lieu de 222.
            </p>
          </div>
        </div>
      </div>
      <div class="sm:grid grid-cols-2 gap-x-12 mt-6">
        <div class="flex flex-col px-4 xs:my-4">
          <label for="offer" class="mb-2">
            <span class="text-red-600">*</span> Offre:</label
          >
          <select
            [(ngModel)]="offerId"
            (change)="setOfferParams($event)"
            class="rounded-md 2xl:text-base xl:text-sm lg:text-sm"
            name="offers"
            required
            id="offer"
          >
            <option
              *ngFor="let offer of offers"
              [value]="offer.id"
            >
              {{ offer.name }}
            </option>
          </select>
        </div>
        <div class="flex flex-col px-4 xs:my-4">
          <label for="" class="mb-2">
            <span class="text-red-600">*</span> Devise:</label
          >
          <input
            class="rounded-md bg-gray-200"
            type="text"
            value="CFA"
            required
            readonly
          />
        </div>
      </div>
      <div class="sm:grid grid-cols-2 gap-x-12 mt-6">
        <div class="flex flex-col px-4 xs:my-4">
          <label for="" class="mb-2">
            <span class="text-red-600">*</span> Prix:</label
          >
          <input
            name="price"
            [value]="offerPrice"
            class="rounded-md bg-gray-200"
            type="text"
            readonly
          />
        </div>
      </div>
      <div
        class="
          flex flex-row
          justify-center
          online-payment-button
          text-white
          p-2
          mx-4
          my-3
          xs:my-6
        "
        *ngIf="isClientBeingVerified == false"
      >
        <button class="w-full" type="submit">Payer en ligne</button>
      </div>
    </form>
    <div
      class="
        flex flex-row
        justify-center
        text-white
        bg-gray-400
        p-2
        mx-4
        my-3
        xs:my-6
      "
      *ngIf="isClientBeingVerified == false"
    >
      <button>
        <a
          class="w-full"
          href="https://www.canalplus-afrique.com/ci/canalbox/particuliers"
          >Annuler</a
        >
      </button>
    </div>

    <div
      class="flex flex-col items-center my-8"
      *ngIf="isClientBeingVerified == true"
    >
      <div><p class="font-semibold">Veuillez patienter</p></div>
      <div class="w-24 my-3">
        <img src="../../../assets/img/spinner.svg" alt="" />
      </div>
    </div>
    <!-- Modal buttons -->
    <button class="hidden" (click)="ngxSmartModalService.getModal('existingClient').open()" id="modal-3"></button>
    <button class="hidden" (click)="ngxSmartModalService.getModal('invalidForm').open()" id="modal-4"></button>

    <!-- End of payment form -->
  </div>
</div>

<ngx-smart-modal #existingClient identifier="existingClient">
  <p>Attention: le client associé à ce numéro ou mail existe déja</p>
  <div class="flex flex-row justify-center my-3">
    <button class="bg-gray-300 mx-4 rounded-lg p-2" (click)="existingClient.close();isClientBeingVerified = false">Modifier mes infos</button>
    <button class="bg-gray-300 mx-4 rounded-lg p-2" id="continue-btn">Continuer</button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #invalidForm identifier="invalidForm">
  <p>Il reste des champs obligatoires !</p>
  <div class="flex flex-row justify-center my-3">
    <button class="bg-gray-300 mx-4 rounded-lg p-2" (click)="invalidForm.close()">Fermer</button>
  </div>
</ngx-smart-modal>
