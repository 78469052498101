<main class="flex-grow responsive-setter">
  <div>
    <p
      class="
        xs:text-center
        py-4
        sm:pt-8
        border-b-2 border-gray-400
        pb-2
        text-gray-500
      "
    >
      Payer mes factures
    </p>
    <router-outlet></router-outlet>
  </div>
</main>
<app-footer></app-footer>
