import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
  styleUrls: ['./intermediate-page.component.css']
})
export class IntermediatePageComponent implements OnInit {

  constructor() { }

  closeWindow(){
    window.close()
  }
  handleEvent(e:any) {
    if (e.action == 'done') {
     this.closeWindow();
    }
  }


  ngOnInit(): void {

  }

}
