import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DataFetcherService } from '../../services/data-fetcher.service';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-canalbox-payment-initialization',
  templateUrl: './canalbox-payment-initialization.component.html',
  styleUrls: ['./canalbox-payment-initialization.component.css'],
})
export class CanalboxPaymentInitializationComponent implements OnInit {
  constructor(
    private router: Router,
    private http: HttpClient,
    private dataFetcherService: DataFetcherService,
    public ngxSmartModalService: NgxSmartModalService
  ) {}
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  address: string = '';
  city: string = '';
  selectedCountry: string = '';
  phoneNumber: string = '';
  routerNumber: string = '';
  offerName: string = '';
  offerPrice: any = '';
  offerId: string = '';
  isClientBeingVerified: boolean = false;
  offers: any = [];
  rawOffers: any = [];

  activateCheckoutRoute() {
    sessionStorage.setItem('isCheckoutRouteActivated', true.toString());
  }

  setOfferParams(args: any) {
    this.offerName = args.target.options[args.target.selectedIndex].text;
    const offer = this.offers.find((x: any) => Number(x.id) === Number(this.offerId));
    if (offer) this.offerPrice = offer.value;
    sessionStorage.setItem('offerId', this.offerId);
  }

  storeNewClientData() {
    sessionStorage.setItem('firstName', this.firstName);
    sessionStorage.setItem('lastName', this.lastName);
    sessionStorage.setItem('email', this.email);
    sessionStorage.setItem('address', this.address);
    sessionStorage.setItem('city', this.city);
    sessionStorage.setItem('routerNumber', this.routerNumber);
    sessionStorage.setItem('country', "Cote d'Ivoire");
    sessionStorage.setItem('offerPrice', this.offerPrice);
    sessionStorage.setItem('offerName', this.offerName);
    sessionStorage.setItem('phoneNumber', this.phoneNumber);
  }

  buildNewClientData() {
    let clientData = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      address: this.address,
      city: this.city,
      country: this.selectedCountry,
      phone: this.phoneNumber,
      currency: 'XOF',
      routerNumber: this.routerNumber,
      offer: sessionStorage.getItem('offerId'),
    };
    let clientDataStringified = JSON.stringify(clientData);
    sessionStorage.setItem('clientDataStringified', clientDataStringified);
  }

  buildExistingClientData(existingClientData: any) {
    let clientData = {
      firstName: existingClientData.firstName,
      lastName: existingClientData.lastName,
      email: existingClientData.email,
      address: existingClientData.address,
      city: existingClientData.city,
      country: this.selectedCountry,
      phone: existingClientData.phone,
      currency: 'XOF',
      routerNumber: this.routerNumber,
      offer: sessionStorage.getItem('offerId'),
    };
    let clientDataStringified = JSON.stringify(clientData);
    sessionStorage.setItem('clientDataStringified', clientDataStringified);
  }

  storeExistingClientData(existingClientData: any) {
    sessionStorage.setItem('firstName', existingClientData.firstName);
    sessionStorage.setItem('lastName', existingClientData.lastName);
    sessionStorage.setItem('email', existingClientData.email);
    sessionStorage.setItem('address', existingClientData.address);
    sessionStorage.setItem('city', existingClientData.city);
    sessionStorage.setItem('routerNumber', this.routerNumber);
    sessionStorage.setItem('country', "Cote d'Ivoire");
    sessionStorage.setItem('offerPrice', this.offerPrice);
    sessionStorage.setItem('offerName', this.offerName);
    sessionStorage.setItem('phoneNumber', this.phoneNumber);
  }

  onSubmit(form: NgForm) {
    if (form.valid) {
      const clientInfo = {
        phone: this.phoneNumber,
        email: this.email,
      };
      console.log(clientInfo);
      this.dataFetcherService
        .findIfCustomerExist(clientInfo)
        .subscribe((response) => {
          console.log(response);
          this.isClientBeingVerified = true;
          if ('email' in response && 'phone' in response) {
            let el1: HTMLElement = document.getElementById(
              'modal-3'
            ) as HTMLElement;
            el1.click();
            let el2: HTMLElement = document.getElementById(
              'continue-btn'
            ) as HTMLElement;
            el2.addEventListener('click', () => {
              this.storeExistingClientData(response);
              this.buildExistingClientData(response);
              this.activateCheckoutRoute();
              this.router.navigateByUrl('/gva/checkout');
            });
          } else {
            this.storeNewClientData();
            this.buildNewClientData();
            this.activateCheckoutRoute();
            this.router.navigateByUrl('/gva/checkout');
          }
        });
    } else {
      let el3: HTMLElement = document.getElementById('modal-4') as HTMLElement;
      el3.click();
    }
  }

  ngOnInit() {
    this.http.get(environment.GET_PACKAGES_API).subscribe((response) => {
      this.rawOffers = response;
      for (let offer of this.rawOffers) {
        this.offers.push({
          id: offer.id,
          name: `${offer.offer.label}  ${offer.duration.label} ${offer.price} CFA`,
          value: offer.price,
        });
      }
      if (!sessionStorage.getItem('offerId')) {
        sessionStorage.setItem('offerId', this.offers[0].id);
      }
      // DEBUGGING
      console.log('Canalbox Packages :', this.offers);
    });
    // DEBUGGING
    console.log('OfferId :', sessionStorage.getItem('offerId'));
    sessionStorage.setItem('selectedCountryShortName', 'ci');
    sessionStorage.setItem('selectedCountryFullName', "Cote D'Ivoire");

    this.offerPrice = null;
    this.dataFetcherService.setReferences();
    if (sessionStorage.length != 0) {
      this.firstName = sessionStorage.getItem('firstName')!;
      this.lastName = sessionStorage.getItem('lastName')!;
      this.email = sessionStorage.getItem('email')!;
      this.city = sessionStorage.getItem('city')!;
      this.address = sessionStorage.getItem('address')!;
      this.phoneNumber = sessionStorage.getItem('phoneNumber')!;
      this.routerNumber = sessionStorage.getItem('routerNumber')!;
      this.offerName = sessionStorage.getItem('offerName')!;
      this.offerPrice = sessionStorage.getItem('offerPrice')!;
      this.selectedCountry = sessionStorage.getItem(
        'selectedCountryShortName'
      )!;
    }
  }

  ngAfterViewInit() {
    this.ngxSmartModalService
      .getModal('existingClient')
      .onAnyCloseEvent.subscribe((modal: NgxSmartModalComponent) => {
        this.isClientBeingVerified = false;
        console.log('Modal closed successfully !');
      });
  }
}
