<!-- Please, do not format this page unless to upset the content  -->

<div class="bg-white p-10">
  <span class="font-bold text-gray-500 text-2xl border-b-4 border-gray-500">Termes et conditions Bizao Plateforme</span>
  <pre class="mt-10">
  En utilisant Bizao Plateforme, l'utilisateur accepte les termes et conditions suivants : 

  1.  L'utilisation de Bizao Plateforme est soumise aux termes et conditions stipulés ici sous ("Les Termes et Conditions"). Les Termes et Conditions constituent un accord entre BIZAO et l'utilisateur. 

  2. L'utilisateur est invité à lire attentivement les conditions générales car elles déterminent ses droits et obligations. L'utilisation de Bizao Plateforme et le fait de cliquer sur la case « J'accepte les conditions générales » sur la page de paiement, constitue une acceptation définitive et irrévocable de la part de l'utilisateur à l'ensemble des conditions générales. L'utilisateur et/ou toute personne en son nom n'aura aucune réclamation et/ou demande, qu'elle soit directe ou indirecte, contre BIZAO et/ou ses propriétaires et/ou toute personne agissant en leur nom, sauf dans les cas prévus aux présentes. 

  3. BIZAO se réserve le droit de changer et/ou de modifier, à tout moment et à sa seule discrétion, les Termes et Conditions, la portée et la disponibilité des Services sur Bizao Plateforme et tout autre aspect lié et/ ou liés à Bizao Plateforme. Un utilisateur qui n'est pas d'accord avec une quelconque partie des Termes et Conditions ne doit pas utiliser Bizao Plateforme. Les termes et conditions se réfèrent à la fois aux hommes et aux femmes, et l'utilisation linguistique du genre masculin est uniquement à des fins de commodité. 

   
  <span class="underline font-semibold">Informations, sécurité et confidentialité :</span>
   

  4. Les données personnelles que l'utilisateur soumet lors de la commande, y compris les détails de ses achats, seront conservées par BIZAO pour sa base de données. BIZAO ne transférera ces informations à aucun tiers, sauf à ses prestataires de services et uniquement pour l'exécution de la commande. 

  L’abonné (le client) accepte que le fournisseur de services BIZAO stocke les informations personnelles pour le compte de GVA et pour être réutilisées pour des cas de réabonnement et/ou gestion de relation client.

  5. BIZAO consacre des ressources et prend des mesures pour empêcher d'autres personnes d'entrer sur Bizao Plateforme et de porter atteinte à la vie privée des utilisateurs de Bizao Plateforme. Cependant, il est impossible d'empêcher totalement d'autres personnes d'entrer sur le site et d'obtenir les informations qu'il contient, sans la connaissance et l'autorisation de BIZAO. Par conséquent, l'utilisateur déclare qu'il n'aura aucune réclamation et/ou demande, de quelque nature que ce soit, contre BIZAO et/ou toute personne agissant en son nom découlant d'une telle utilisation non autorisée des informations si elle devait malheureusement se produire. 

   
  <span class="underline font-semibold">Propriété et droits :</span>

   
  6. Bizao Plateforme, les marques déposées et les modèles qu'il contient sont la propriété de Bizao et/ou d'autres tiers qui font de la publicité sur Bizao Plateforme. L'utilisateur ne doit pas changer et/ou modifier le contenu et/ou les informations et/ou les données et/ou le logiciel et/ou les Services et/ou tout autre élément contenu dans Bizao Plateforme. En outre, l'utilisateur ne doit pas copier et/ou reproduire et/ou republier et/ou distribuer et/ou vendre et/ou transférer et/ou modifier et/ou utiliser, de quelque manière que ce soit, directement et/ou indirectement, des données et / ou des informations et/ou tout autre élément contenu et/ou faisant partie de Bizao Plateforme, (sauf télécharger, afficher et/ou imprimer une copie des informations présentées sur Bizao Plateforme, sur un seul ordinateur, pour usage personnel et non commercial). 

  7. Rien de ce qui est contenu sur Bizao Plateforme ne doit être interprété comme accordant une licence et/ou un droit d'utilisation de l'un des droits de propriété affichés sur Bizao Plateforme, ni aucune renonciation de quelque nature que ce soit à ces droits par leurs propriétaires. 

  8. L'utilisation de toutes marques déposées et/ou autres droits de propriété, contenus dans Bizao Plateforme, sur tout autre site Web et/ou dans tout autre environnement d'ordinateurs en réseau est strictement interdite. L'utilisateur ne doit publier et/ou transmettre aucun matériel illégal, menaçant, calomnieux, diffamatoire, obscène, indécent, incendiaire, pornographique ou profane et/ou tout autre matériel qui pourrait constituer et/ou encourager un comportement qui serait considéré comme une infraction et/ou engager la responsabilité civile. 

  </pre>

</div>
