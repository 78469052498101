import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataFetcherService } from '../../services/data-fetcher.service';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-canalbox-payment-checkout',
  templateUrl: './canalbox-payment-checkout.component.html',
  styleUrls: ['./canalbox-payment-checkout.component.css'],
})
export class CanalboxPaymentCheckoutComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  constructor(
    private http: HttpClient,
    private router: Router,
    private dataFetcherService: DataFetcherService,
    public ngxSmartModalService: NgxSmartModalService
  ) {}
  referenceNumber: string = '';
  partnerReference: string = '';
  isPaymentPageOpened: boolean = false;
  isPaymentMethodChosen: boolean = false;
  isPolicyAccepted: boolean = false;
  paymentProcessingStatus = 'notStarted';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  address: string = '';
  city: string = '';
  countryFullName: string = '';
  countryShortName: string = '';
  phoneNumber: string = '';
  routerNumber: string = '';
  offerName: string = '';
  offerPrice: string = '';
  tel: string = '';
  selectedTelco: string = '';
  currentDate = new Date();
  clientData: any;
  popUpPayment: any;
  paymentMethod: string = '';
  receiptNumber: string = '';
  checkStatusAttempts: number = 0;
  isPopUpBlocked = false;
  newTab: any;
  mnoPrefix = '';
  phone = '';

  /* Generate Random Number */
  randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /* Print client receipt after successful transaction */
  printReceipt() {
    window.print();
  }

  /*  Store payment gateway */
  setSelectedTelco(event: any) {
    if (event.target.checked) {
      this.selectedTelco = event.target.value.split('-')[0];
      this.mnoPrefix = event.target.value.split('-')[1];
      sessionStorage.setItem('mno', this.selectedTelco);
      sessionStorage.setItem('mno_prefix', this.mnoPrefix);
      return event.target.checked;
    }
  }

  makeNewPayment() {
    this.router.navigateByUrl('/gva');
  }

  /* Function to open payment pop-up */
  popupWindow(url: any, windowName: any, win: any, w: any, h: any) {
    const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
    const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
    return win.open(
      url,
      windowName,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
    );
  }

  checkIsPolicyAccepted(event: any) {
    if (event.target.checked) {
      this.isPolicyAccepted = true;
      sessionStorage.setItem(
        'isPolicyAccepted',
        this.isPolicyAccepted.toString()
      );
      return event.target.checked;
    } else if (!event.target.checked) {
      this.isPolicyAccepted = false;
      sessionStorage.setItem(
        'isPolicyAccepted',
        this.isPolicyAccepted.toString()
      );
      return event.target.checked;
    }
  }

  goBackHome() {
    sessionStorage.clear();
    this.router.navigateByUrl('/');
  }

  bringBackPopUp() {
    this.popUpPayment.focus();
  }

  handleEvent(e: any) {
    if (e.action == 'done') {
      this.paymentProcessingStatus = 'Expired';
      this.isPaymentPageOpened = false;
      this.popUpPayment.close();
      return;
    }
  }

  mobileMoneyApi(): Observable<any> {
    let mno = sessionStorage.getItem('mno');
    let email = sessionStorage.getItem('email');
    let paymentBodyRequest = {
      country: this.countryShortName,
      mno: mno,
      currency: 'XOF',
      amount: this.offerPrice || sessionStorage.getItem('offerPrice'),
      offerId: sessionStorage.getItem('offerId'),
      state: environment.GVA_STATE,
      return_url: environment.PARTNER_RETURN_URL,
      cancel_url: environment.PARTNER_CANCEL_URL,
      reference: 'CANALBOX',
      email: email!.toLowerCase(),
      referenceNumber: sessionStorage.getItem('referenceNumber')!,
      partnerReference: sessionStorage.getItem('partnerReference')!,
      router: this.routerNumber,
      phone: this.phone,
      routersId: '',
      lang: '',
      paymentMethod: '',
    };
    return this.http.post(
      environment.MOBILE_MONEY_PAYMENT_API,
      paymentBodyRequest
    );
  }

  cardPaymentApi(): Observable<any> {
    let email = sessionStorage.getItem('email');
    let paymentBodyRequest = {
      country: this.countryShortName,
      currency: 'XOF',
      lang: 'fr',
      amount: this.offerPrice || sessionStorage.getItem('offerPrice'),
      state: environment.GVA_STATE,
      return_url: environment.PARTNER_RETURN_URL,
      cancel_url: environment.PARTNER_CANCEL_URL,
      reference: 'Canalbox_vmc2',
      email: email!.toLowerCase(),
      referenceNumber: this.referenceNumber,
      partnerReference: this.partnerReference,
      router: this.routerNumber,
    };
    return this.http.post(environment.CARD_PAYMENT_API, paymentBodyRequest);
  }

  getMobileMoneyStatus(): Observable<any> {
    let mno = sessionStorage.getItem('mno');
    let orderId = sessionStorage.getItem('orderId');
    let getStatusBodyRequest = {
      country: this.countryShortName,
      mno: mno,
      orderId: orderId,
      lang: 'fr',
      channel: 'web',
    };

    return this.http.post(
      environment.MOBILE_MONEY_GET_STATUS_API,
      getStatusBodyRequest
    );
  }

  getCardStatus(): Observable<any> {
    let orderId = sessionStorage.getItem('orderId');
    let getStatusBodyRequest = {
      country: this.countryShortName,
      orderId: orderId,
    };
    return this.http.post(
      environment.CARD_GET_STATUS_API,
      getStatusBodyRequest
    );
  }

  reloadPaymentPage = () => {
    this.isPopUpBlocked = false;
    let paymentURL = sessionStorage.getItem('payment_url')!;
    this.newTab = window.open(paymentURL);
    let paymentMethodGateway = sessionStorage.getItem('mno');
    if ( 
      paymentMethodGateway != 'visacard' &&
      paymentMethodGateway != 'mastercard'
    ) {
      const timer1 = setInterval(() => {
        if (this.newTab.closed && this.paymentProcessingStatus != 'Expired') {
          this.isPopUpBlocked = false;
          // DEBUGGING
          clearInterval(timer1);
          this.isPaymentPageOpened = false;
          this.paymentProcessingStatus = 'isBeingChecked';
          this.getMobileMoneyStatus().subscribe(
            (response) => {
              console.log(response);
              if (response.status == 'Failed' || null) {
                this.paymentProcessingStatus = 'Failed';
              } else if (response.status == 'Successful') {
                this.paymentProcessingStatus = 'Successful';
              } else if (response.status == 'Successfull') {
                this.paymentProcessingStatus = 'Successful';
              } else if (response.status == 'InProgress') {
                this.paymentProcessingStatus = 'InProgress';
                this.checkStatusAttempts = 1;
              } else if (response.status == 400) {
                this.paymentProcessingStatus = 'Failed';
              } else if (response.requestError) {
                this.paymentProcessingStatus = 'Failed';
              }
            },
            (err) => {
              console.log(err);
              this.paymentProcessingStatus = 'Failed';
            }
          );
        }
      }, 1000);
    } else {
      const timer2 = setInterval(() => {
        if (this.newTab.closed && this.paymentProcessingStatus != 'Expired') {
          clearInterval(timer2);
          this.isPaymentPageOpened = false;
          this.paymentProcessingStatus = 'isBeingChecked';
          console.log('vmc');
          this.getCardStatus().subscribe((response) => {
            if (response.status == 'Failed' || null) {
              this.paymentProcessingStatus = 'Failed';
            } else if (response.status == 'Successful') {
              this.paymentProcessingStatus = 'Successful';
            } else if (response.status == 'Successfull') {
              this.paymentProcessingStatus = 'Successful';
            } else if (response.status == 'InProgress') {
              this.paymentProcessingStatus = 'InProgress';
              this.checkStatusAttempts = 1;
            } else if (response.status == 400) {
              this.paymentProcessingStatus = 'Failed';
            }
          });
        }
      }, 1000);
    }
  };

  changePaymentMethod() {
    this.dataFetcherService.setReferences();
    sessionStorage.removeItem('mno');
    sessionStorage.removeItem('isPolicyAccepted');
    this.paymentProcessingStatus = 'notStarted';
    setTimeout(function () {
      location.reload();
    }, 500);
  }

  mobileMoneyPayment() {
    this.paymentProcessingStatus = 'waitingForPopup';
    this.mobileMoneyApi().subscribe((response) => {
      console.log(response);
      if (response.payment_url == null) {
        sessionStorage.removeItem('referenceNumber')
        alert('Veuillez à nouveau réessayer votre paiement');
        this.router.navigateByUrl('/gva');
        this.paymentProcessingStatus = 'notStarted';
        sessionStorage.removeItem('mno');
        sessionStorage.removeItem('isPolicyAccepted');
        this.dataFetcherService.setReferences();
        setTimeout(function () {
          location.reload();
        }, 200);
        return;
      }
      sessionStorage.setItem('orderId', response.orderId);
      sessionStorage.setItem('payment_url', response.payment_url);
      let win = this.popupWindow(response.payment_url, 'gva', window, 700, 900);
      if (!win) {
        this.dataFetcherService.setReferences();
        this.paymentProcessingStatus = '';
        this.isPopUpBlocked = true;
        return;
      }
      this.isPaymentPageOpened = true;

      this.paymentProcessingStatus = '';
      const timer1 = setInterval(() => {
        if (win.closed && this.paymentProcessingStatus != 'Expired') {
          clearInterval(timer1);
          this.isPaymentPageOpened = false;
          this.paymentProcessingStatus = 'isBeingChecked';
        sessionStorage.removeItem('referenceNumber')
          this.getMobileMoneyStatus().subscribe(
            (response) => {
              console.log(response);
              if (response.status == 'Failed' || null) {
                this.paymentProcessingStatus = 'Failed';
              } else if (response.status == 'Successful') {
                this.paymentProcessingStatus = 'Successful';
              } else if (response.status == 'Successfull') {
                this.paymentProcessingStatus = 'Successful';
              } else if (response.status == 'InProgress') {
                this.paymentProcessingStatus = 'InProgress';
                this.checkStatusAttempts = 1;
              } else if (response.status == 400) {
                this.paymentProcessingStatus = 'Failed';
              } else if (response.requestError) {
                this.paymentProcessingStatus = 'Failed';
              }
            },
            (err) => {
              console.log(err);
              this.paymentProcessingStatus = 'Failed';
            }
          );
        }
      }, 1000);

      const timer2 = setInterval(() => {
        clearInterval(timer2);
        this.popUpPayment = win;
      }, 1000);
    });
  }

  cardPayment() {
    this.paymentProcessingStatus = 'waitingForPopup';
    this.cardPaymentApi().subscribe((response) => {
      console.log(response);
      if (response.payment_url == null) {
        sessionStorage.removeItem('referenceNumber')
        alert('Veuillez à nouveau réessayer votre paiement');
        this.router.navigateByUrl('/gva');
        this.paymentProcessingStatus = 'notStarted';
        sessionStorage.removeItem('mno');
        sessionStorage.removeItem('isPolicyAccepted');
        this.dataFetcherService.setReferences();
        return;
      }
      sessionStorage.setItem('orderId', response.orderId);
      sessionStorage.setItem('payment_url', response.payment_url);
      let win = this.popupWindow(response.payment_url, 'gva', window, 700, 900);
      if (!win) {
        this.paymentProcessingStatus = '';
        this.isPopUpBlocked = true;
        return;
      }
      this.isPaymentPageOpened = true;
      console.log('pop-up opened successfully !');
      this.paymentProcessingStatus = '';
      const timer3 = setInterval(() => {
        if (win.closed && this.paymentProcessingStatus != 'Expired') {
          clearInterval(timer3);
          sessionStorage.removeItem('referenceNumber')
          this.isPaymentPageOpened = false;
          this.paymentProcessingStatus = 'isBeingChecked';
          this.getCardStatus().subscribe((response) => {
            if (response.status == 'Failed' || null) {
              this.paymentProcessingStatus = 'Failed';
            } else if (response.status == 'Successful') {
              this.paymentProcessingStatus = 'Successful';
            } else if (response.status == 'Successfull') {
              this.paymentProcessingStatus = 'Successful';
            } else if (response.status == 'InProgress') {
              this.paymentProcessingStatus = 'InProgress';
              this.checkStatusAttempts = 1;
            } else if (response.status == 400) {
              this.paymentProcessingStatus = 'Failed';
            }
          });
        }
      }, 1000);

      const timer4 = setInterval(() => {
        clearInterval(timer4);
        this.popUpPayment = win;
      }, 1000);
    });
  }

  doubleCheckStatus() {
    this.paymentProcessingStatus = 'isBeingChecked';
    if (sessionStorage.getItem('paymentMethod') == 'Mobile Money') {
      this.getMobileMoneyStatus().subscribe((response) => {
        console.log(response);
        if (response.status == 'Failed' || null) {
          this.paymentProcessingStatus = 'Failed';
        } else if (response.status == 'Successful') {
          this.paymentProcessingStatus = 'Successful';
        } else if (response.status == 'Successfull') {
          this.paymentProcessingStatus = 'Successful';
        } else if (response.status == 'InProgress') {
          this.paymentProcessingStatus = 'InProgress';
          this.checkStatusAttempts = 2;
        } else if (response.status == 400) {
          this.paymentProcessingStatus = 'Failed';
        }
      });
    } else if (sessionStorage.getItem('paymentMethod') == 'Carte Bancaire') {
      this.getCardStatus().subscribe((response) => {
        console.log(response);
        if (response.status == 'Failed' || null) {
          this.paymentProcessingStatus = 'Failed';
        } else if (response.status == 'Successful') {
          this.paymentProcessingStatus = 'Successful';
        } else if (response.status == 'Successfull') {
          this.paymentProcessingStatus = 'Successful';
        } else if (response.status == 'InProgress') {
          this.paymentProcessingStatus = 'InProgress';
          this.checkStatusAttempts = 2;
        } else if (response.status == 400) {
          this.paymentProcessingStatus = 'Failed';
        }
      });
    }
  }

  processPayment() {

    // this.dataFetcherService.setReferences();
    // DEBUGGING
    console.log('Client Data to be stored :', this.clientData);

    // DEBUGGING
    console.log(this.phoneNumber.substring(0, 2));

    let paymentMethodGateway = sessionStorage.getItem('mno')!;
    let mnoPrefix = sessionStorage.getItem('mno_prefix')!;

    //DEBUGGING
    console.log('The mno prefix:', mnoPrefix);
    if (
      mnoPrefix != this.phoneNumber.substring(0, 2) &&
      paymentMethodGateway != 'bank'
    ) {
      let element: HTMLElement = document.getElementById(
        'modal-1'
      ) as HTMLElement;
      element.click();
      return;
    }

    if (!sessionStorage.getItem('mno')) {
    }

    if (sessionStorage.getItem('isPolicyAccepted') != 'true') {
      let element: HTMLElement = document.getElementById(
        'modal-2'
      ) as HTMLElement;
      element.click();
      return;
    }

    if (paymentMethodGateway != 'bank') {
      this.http
        .post(environment.ADD_CUSTOMER_API, this.clientData)
        .subscribe((response) => console.log('Server response :', response));
      this.paymentMethod = 'Mobile Money';
      sessionStorage.setItem('paymentMethod', 'Mobile Money');
      this.mobileMoneyPayment();
    } else {
      this.http
        .post(environment.ADD_CUSTOMER_API, this.clientData)
        .subscribe((response) => console.log('Server response :', response));
      this.paymentMethod = 'Carte Bancaire';
      sessionStorage.setItem('paymentMethod', 'Carte Bancaire');
      this.cardPayment();
    }
  }

  ngOnInit() {
    this.receiptNumber = `INV${this.randomIntFromInterval(
      environment.MIN,
      environment.MAX
    )}`;

    this.http.get(environment.GET_PACKAGES_API).subscribe((response: any) => {
      const selectedOffer = response.find((offer: any) => Number(offer.id) === Number(sessionStorage.getItem('offerId')));
      console.log(selectedOffer);
      if (selectedOffer) this.offerPrice = selectedOffer.price;
    });

    if (sessionStorage.length != 0) {
      sessionStorage.setItem(
        'isPolicyAccepted',
        this.isPolicyAccepted.toString()
      );
      this.firstName = sessionStorage.getItem('firstName')!;
      this.lastName = sessionStorage.getItem('lastName')!;
      this.address = sessionStorage.getItem('address')!;
      this.email = sessionStorage.getItem('email')!;
      this.countryFullName = sessionStorage.getItem('selectedCountryFullName')!;
      this.countryShortName = sessionStorage.getItem(
        'selectedCountryShortName'
      )!;
      this.phoneNumber = sessionStorage.getItem('phoneNumber')!;
      this.city = sessionStorage.getItem('city')!;
      console.log(sessionStorage.getItem('routerNumber'))
      this.routerNumber = sessionStorage.getItem('routerNumber')!;
      // this.offerPrice = sessionStorage.getItem('offerPrice')!;
      this.offerName = sessionStorage.getItem('offerName')!;
      if (sessionStorage.getItem('clientDataStringified')) {
        this.clientData = JSON.parse(
          sessionStorage.getItem('clientDataStringified')!
        );
      }
      this.referenceNumber = sessionStorage.getItem('referenceNumber')!;
      this.partnerReference = sessionStorage.getItem('partnerReference')!;
      this.phone = `225${sessionStorage.getItem('phoneNumber')}`;
      console.log(this.phone);
    }
  }

  ngOnDestroy() {
    if (sessionStorage.getItem('mno')) sessionStorage.removeItem('mno');
  }

  ngAfterViewInit() {
    if (sessionStorage.getItem('mno')) sessionStorage.removeItem('mno');
  }
}
