import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataFetcherService {
  constructor(private http: HttpClient) {}
  fetchReferences(): Observable<any> {
    return this.http.get(environment.GET_REFERENCE_API);
  }

  setReferences() {
    this.fetchReferences().subscribe((response) => {
      if (response != null) {
        sessionStorage.setItem('referenceNumber', response.referenceNumber);
        sessionStorage.setItem('partnerReference', response.partnerReference);
      }
    });
  }

  findIfCustomerExist(clientInfo: {phone: string, email: string}): Observable<any> {
    return this.http.post(environment.FIND_CUSTOMER_API, clientInfo)
  }

}
