import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DataFetcherService } from '../../services/data-fetcher.service';
import { Observable } from 'rxjs';
import { isDevMode } from '@angular/core';

@Component({
  selector: 'app-canalbox-payment-homepage',
  templateUrl: './canalbox-payment-homepage.component.html',
  styleUrls: ['./canalbox-payment-homepage.component.css'],
})
export class CanalboxPaymentHomepageComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private dataFetcherService: DataFetcherService
  ) {}
  getUserInfo(id: any): Observable<any> {
    return this.http.get(`${environment.GET_USER_INFO_API}/${id}`);
  }

  ngOnInit(): void {
    // Hiding console logs on production
    console.log(sessionStorage.getItem('referenceNumber'))
    if (!isDevMode()) {
      console.log = function () {};
    }

    this.activatedRoute.params.subscribe((param) => {
      console.log(" ****************** " + JSON.stringify(param))
      if (param['id']) {
        sessionStorage.setItem('isCheckoutRouteActivated', true.toString());
        this.dataFetcherService.setReferences();
        let id = param['id'];
        console.log(id);
        this.getUserInfo(id).subscribe((response) => {
          console.log(response);
          sessionStorage.setItem('firstName', response.firstName);
          sessionStorage.setItem('lastName', response.lastName);
          sessionStorage.setItem('email', response.email);
          sessionStorage.setItem('phoneNumber', response.phone);
          sessionStorage.setItem('address', response.address);
          sessionStorage.setItem('selectedCountryFullName', "Cote D'ivoire");
          sessionStorage.setItem('selectedCountryShortName', response.country);
          sessionStorage.setItem('city', response.city);
          sessionStorage.setItem('routerNumber', response.routers);
          sessionStorage.setItem(
            'offerName',
            `${response.packages.offer.label} ${response.packages.offer.debit} ${response.packages.duration.label}`
          );
          sessionStorage.setItem('offerPrice', response.packages.price);
        });
        this.router.navigateByUrl('/gva/checkout');
        setTimeout(function () {
          location.reload();
        }, 1000);
      } else return;
    });
  }
}
