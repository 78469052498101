// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  MOBILE_MONEY_PAYMENT_API:
    'https://pprd-onlinepay-api-gateway.bizao.com/api/transaction',
  CARD_PAYMENT_API:
    'https://pprd-onlinepay-api-gateway.bizao.com/api/transaction/debitCard',
  MOBILE_MONEY_GET_STATUS_API:
    'https://pprd-onlinepay-api-gateway.bizao.com/api/transaction/status',
  CARD_GET_STATUS_API:
    'https://pprd-onlinepay-api-gateway.bizao.com/api/transaction/card/status',
  ADD_CUSTOMER_API: 'https://pprd-onlinepay-api-gateway.bizao.com/api/customer',
  GET_REFERENCE_API:
    'https://pprd-onlinepay-api-gateway.bizao.com/api/transaction/references',
  GET_USER_INFO_API: 'https://pprd-onlinepay-api-gateway.bizao.com/api/link',
  GET_PACKAGES_API: 'https://pprd-onlinepay-api-gateway.bizao.com/api/packages',
  PARTNER_CANCEL_URL: 'https://pprd-onlinepay.bizao.com/gva/intermediate-page',
  PARTNER_RETURN_URL:
    'https://pprd-onlinepay.bizao.com/gva/intermediate-page-returnurl',
  GVA_STATE: 'BIZAOTnestj',
  GVA_REFERENCE: 'CANALBOX',
  MIN: 15000000000000000,
  MAX: 39000000000000000,
  FIND_CUSTOMER_API: 'https://pprd-onlinepay-api-gateway.bizao.com/api/find/customer'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
