import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CheckRouteActivationService {
  constructor() {}
  isCheckoutRouteActivated() {
    return (
      sessionStorage.getItem('isCheckoutRouteActivated') == true.toString()
    );
  }
}
