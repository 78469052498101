import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intermediate-page-returnurl',
  templateUrl: './intermediate-page-returnurl.component.html',
  styleUrls: ['./intermediate-page-returnurl.component.css'],
})
export class IntermediatePageReturnurlComponent implements OnInit {
  constructor() {}
  closeWindow() {
    window.close();
  }
  handleEvent(e: any) {
    if (e.action == 'done') {
      this.closeWindow();
    }
  }

  ngOnInit(): void {}
}
