<div class="grid grid-cols-12 my-4">
  <div
    class="col-span-3 xs:hidden"
    [ngClass]="{ 'opacity-20': isPaymentPageOpened == true }"
  >
    <div class="flex flex-col">
      <div
        class="
          bg-white
          shadow-md
          h-40
          my-4
          mr-4
          flex flex-col
          justify-center
          py-4
        "
      >
        <img
          class="object-scale-down h-24 my-auto"
          src="../../../assets/img/canalbox-logo.png"
          alt=""
        />
        <p class="uppercase mx-auto my-auto font-bold text-gray-900">
          CANALBOX cote d'ivoire
        </p>
      </div>
      <div class="flex-grow mr-4" *ngIf="paymentProcessingStatus != 'Expired'">
        <div class="bg-white shadow-md h-80">
          <p class="text-gray-400 py-3 pl-4 text-xl border-b-2 border-gray-400">
            Details du service
          </p>
          <p class="uppercase font-bold pt-4 pb-2 pl-4">
            canalbox cote d'ivoire
          </p>
          <p class="pb-2 pl-4 font-semibold">{{ offerName }}</p>
          <div class="flex flex-row justify-between px-4 2xl:gap-6 xl:gap-10">
            <div>
              <p class="uppercase text-xs text-gray-400">n° de refrence</p>
              <p class="mt-2">{{ referenceNumber }}</p>
            </div>
            <div>
              <p class="uppercase text-xs text-gray-400">Ref prestataire</p>
              <p class="mt-2">{{ partnerReference }}</p>
            </div>
          </div>
          <div class="my-2 px-4">
            <p class="uppercase text-gray-400 text-xs">date de service:</p>
            <p>{{ currentDate | date: "yyyy-MM-dd" }}</p>
          </div>
        </div>
        <div
          class="
            total-amount
            text-white
            h-12
            flex flex-col
            justify-center
            text-xl
            font-medium
            pl-3
          "
        >
          <p>Total : {{ offerPrice }} CFA</p>
        </div>
      </div>

      <div class="my-6 bg-white mr-4">
        <p
          class="
            text-gray-400
            py-3
            pl-4
            text-xl
            border-b-2 border-gray-400
            uppercase
          "
        >
          coordonnées
        </p>
        <p class="uppercase font-bold py-4 pl-4">canalbox cote d'ivoire</p>
        <div
          class="flex flex-row justify-between text-xs text-gray-400 my-3 gap-3"
        >
          <p class="uppercase ml-4">adresse</p>
          <p class="lowercase mr-5">
            1er Etage Immeuble Grren Buro, Cocody Ambassade, Rue Viviane
          </p>
        </div>
        <div
          class="flex flex-row justify-between text-xs text-gray-400 my-3 gap-3"
        >
          <p class="uppercase ml-4">tel</p>
          <p class="uppercase 2xl:mr-60 lg:mr-28">1341</p>
        </div>
        <div
          class="flex flex-row justify-between text-xs text-gray-400 my-3 gap-3"
        >
          <p class="uppercase ml-4">web</p>
          <a
            class="text-blue-300 2xl:mr-44 lg:mr-14"
            href="https://www.canalplus-afrique.com/ci/canalbox/particuliers"
            target="_blank"
            >www.canalbox.ci</a
          >
        </div>
        <div
          class="
            flex flex-row flex-wrap
            justify-between
            text-xs text-gray-400
            my-3
          "
        >
          <p class="uppercase ml-4">e-mail</p>
          <div class="flex flex-row flex-wrap mr-5 px-2">
            <p class="lowercase">relationclientcanalbox</p>
            <p class="lowercase">cotedivoire@gva.africa</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-9 h-full my-4 py-0 xs:col-span-12 overflow-hidden">
    <div
      class="bg-white"
      *ngIf="paymentProcessingStatus != 'Expired'"
      [ngClass]="{ 'opacity-20': isPaymentPageOpened == true }"
    >
      <p
        class="
          2xl:text-xl
          text-gray-500
          pt-5
          pl-10
          xs:text-xl
          text-center
          mr-8
          my-auto
        "
      >
        Informations personnelles
      </p>
      <form class="py-8 px-4">
        <div
          class="
            justify-items-start
            w-full
            2xl:ml-10
            xl:ml-2
            lg:ml-1
            sm:grid
            grid-cols-12
            xs:my-2
          "
        >
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="lastName"
              ><span class="text-red-600">*</span> Nom de famille</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ lastName }}"
              readonly
            />
          </div>

          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="firstName"
              ><span class="text-red-600">*</span> Prénom :</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ firstName }}"
              readonly
            />
          </div>
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="tel"
              ><span class="text-red-600">*</span> Téléphone :</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ phoneNumber }}"
              readonly
            />
          </div>
        </div>
        <div
          class="
            justify-items-start
            w-full
            my-10
            2xl:ml-10
            xl:ml-2
            lg:ml-1
            sm:grid
            grid-cols-12
            xs:my-2
          "
        >
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="email"
              ><span class="text-red-600">*</span> Email :</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ email }}"
              readonly
            />
          </div>
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="city"
              ><span class="text-red-600">*</span> Numéro router :</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ routerNumber }}"
              readonly
            />
          </div>
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="address"
              ><span class="text-red-600">*</span> Adresse</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ address }}"
              readonly
            />
          </div>
        </div>
        <div
          class="
            justify-items-start
            w-full
            my-10
            2xl:ml-10
            xl:ml-2
            lg:ml-1
            sm:grid
            grid-cols-12
            xs:my-2
          "
        >
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="city"
              ><span class="text-red-600">*</span> Ville :</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ city }}"
              readonly
            />
          </div>
          <div class="flex flex-col col-span-4 xs:my-4">
            <label for="country"
              ><span class="text-red-600">*</span> Pays :</label
            >
            <input
              class="rounded-md bg-gray-200"
              type="text"
              value="{{ countryFullName }}"
              readonly
            />
          </div>
        </div>
      </form>
    </div>
    <!-- Bring back Pop up -->
    <div class="text-center text-2xl p-2" *ngIf="isPaymentPageOpened">
      <p>
        Si vous n'avez pas terminé votre opération, vous pouvez toutefois
        continuer.
      </p>
      <button class="text-blue-600" (click)="bringBackPopUp()">
        Cliquer ici pour continuer
      </button>
    </div>
    <!-- Bring back Pop up -->
    <div
      class="flex flex-row justify-center my-6 px-4"
      *ngIf="paymentProcessingStatus == 'notStarted'"
      [ngClass]="{ 'opacity-20': isPaymentPageOpened == true }"
    >
      <p class="text-xl text-gray-500 font-light">
        Veuillez effectuer votre paiement dans les délais suivants :
        <countdown
          class="text-red-600"
          #delay
          [config]="{ leftTime: 900, format: 'm:s' }"
          (event)="handleEvent($event)"
        ></countdown>
      </p>
    </div>

    <div class="sm:hidden bg-white my-2 px-4 py-3">
      <p class="text-">
        Nom de l'offre : <span class="font-semibold">{{ offerName }}</span>
      </p>
      <p class="text-">
        Prix de l'offre :
        <span class="font-semibold">{{ offerPrice }} CFA</span>
      </p>
      <p class="text-">
        Référence : <span class="font-semibold">{{ referenceNumber }}</span>
      </p>
    </div>

    <!-- Payment method choice section -->
    <div
      class="bg-white px-6 py-10"
      *ngIf="paymentProcessingStatus == 'notStarted'"
      [ngClass]="{ 'opacity-20': isPaymentPageOpened == true }"
    >
      <p class="text-gray-900 font-semibold mt-2 mb-4">Choisissez votre pays</p>
      <input
        type="text"
        class="w-full rounded-md text-gray-500"
        name=""
        id=""
        value="{{ countryFullName }}"
        readonly
      />
      <div class="p-3 my-4">
        <p class="text-red-500 font-light italic my-1 py-1 xs:my-6">
          Cher Abonné, avant de continuer le processus merci de vérifier que vous
          disposez du montant de la transaction sur votre compte Mobile Money ou
          Compte Bancaire.
        </p>
        <p class="text-gray-900 font-semibold my-4 py-4 xs:my-6">
          Cliquez sur le mode de paiement de votre choix
        </p>
        <div class="sm:flex flex-row justify-between py-3 shadow-lg p-4">
          <div class="flex flex-row xs:my-4">
            <input
              type="radio"
              value="orange-07"
              name="paymentMethod"
              id="om"
              (change)="setSelectedTelco($event)"
            />
            <label class="mx-4" for="om"
              ><img
                class="image-on-screens"
                src="../../../assets/img/om-logo-colored.png"
                alt=""
            /></label>
          </div>
          <div class="flex flex-row xs:my-4">
            <input
              type="radio"
              value="mtn-05"
              name="paymentMethod"
              id="mtn"
              (change)="setSelectedTelco($event)"
            />
            <label class="mx-4" for="mtn"
              ><img
                class="image-on-screens"
                src="../../../assets/img/mtn-logo-colored.png"
                alt=""
            /></label>
          </div>
          <div class="flex flex-row xs:my-4">
            <input
              type="radio"
              value="moov-01"
              name="paymentMethod"
              id="moov"
              (change)="setSelectedTelco($event)"
            />
            <label class="mx-4" for="moov"
              ><img
                class="image-on-screens"
                src="../../../assets/img/moov-logo-colored.png"
                alt=""
            /></label>
          </div>
          <div class="flex flex-row xs:my-4">
            <input
              type="radio"
              value="bank"
              name="paymentMethod"
              id="visa"
              (change)="setSelectedTelco($event)"
            />
            <label class="mx-4" for="visa"
              ><img
                class="image-on-screens"
                src="../../../assets/img/visacard-logo-colored.png"
                alt=""
            /></label>
          </div>
          <div class="flex flex-row xs:my-4">
            <input
              type="radio"
              value="bank"
              name="paymentMethod"
              id="mastercard"
              (change)="setSelectedTelco($event)"
            />
            <label class="mx-4" for="mastercard"
              ><img
                class="image-on-screens"
                src="../../../assets/img/mastercard-logo-colored.png"
                alt=""
            /></label>
          </div>
        </div>
      </div>
      <div class="shadow-lg my-4">
        <div class="bg-gray-200 w-full flex flex-row gap-4 p-4">
          <input
            type="checkbox"
            name="paymentMethod"
            (change)="checkIsPolicyAccepted($event)"
          />
          <p>COCHEZ LA CASE POUR ACCEPTER NOS CONDITIONS GENERALES</p>
        </div>
        <div
          class="flex flex-row justify-start gap-2 bg-gray-200 pl-20 text-sm"
        >
          <span class="text-xl">&#183; </span>
          <a href="/gva/terms" target="_blank" class="underline"
            >J'accepte les conditions</a
          >
        </div>
        <div
          class="
            flex flex-row
            justify-start
            gap-2
            bg-gray-200
            pl-20
            text-gray-500
            sm:pr-10
          "
        >
          <span class="text-xl">&#183; </span>
          <p class="text-sm pb-2">
            J’accepte par la présente, la sauvegarde des informations
            personnelles que j’ai fournies dans la mesure nécessaire au
            traitement de cette transaction et pour de futurs paiements.
          </p>
        </div>
      </div>
      <div>
        <div class="flex flex-row justify-end mt-10">
          <button
            class="mx-8 text-gray-600 text-xl font-bold"
            (click)="goBackHome()"
          >
            Annuler
          </button>
          <button
            type="button"
            class="mx-8 total-amount text-white p-4 rounded-sm xs:rounded p-2"
            (click)="processPayment()"
          >
            Procéder au paiement
          </button>
        </div>
      </div>
      <div class="sm:hidden mt-20 mb-2 ml-4 flex flex-col">
        <div class="my-2"><p class="text-gray-500 underline">Contact :</p></div>
        <div>
          <p class="text-gray-400">
            Adresse:
            <span class="text-xs"
              >1er etage immeuble grren buro, cocody ambassade, rue
              viviane</span
            >
          </p>
          <p class="text-gray-400">Tel : <span class="text-xs">1341</span></p>
          <p class="text-gray-400">
            Web :
            <span class="text-xs text-gray-400">
              <a
                class="text-blue-300 2xl:mr-44 lg:mr-14"
                href="https://www.canalplus-afrique.com/ci/canalbox/particuliers"
                target="_blank"
                >www.canalbox.ci</a
              >
            </span>
          </p>
          <p class="text-gray-400">
            Email :
            <span class="text-xs"
              >relationclientcanalboxcotedivoire@gva.africa</span
            >
          </p>
        </div>
      </div>
    </div>

    <!-- transaction status page -->


    <!-- Transaction treatment-->
    <div
      class="bg-white py-8 w-full"
      *ngIf="paymentProcessingStatus == 'isBeingChecked'"
    >
      <div class="flex flex-col items-center">
        <div>
          <p class="font-semibold">Vérification de la transaction</p>
        </div>
        <div class="w-32 my-3">
          <img src="../../../assets/img/spinner.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- Waiting Pop-up to open -->
    <!-- Transaction treatment-->
    <div
      class="bg-white py-8 w-full"
      *ngIf="paymentProcessingStatus == 'waitingForPopup'"
    >
      <div class="flex flex-col items-center">
        <div>
          <p class="font-semibold">Chargement de la page de paiement</p>
        </div>
        <div class="w-32 my-3">
          <img src="../../../assets/img/spinner.svg" alt="" />
        </div>
      </div>
    </div>

    <!-- Success -->
    <div
      class="bg-white py-8 w-full"
      *ngIf="paymentProcessingStatus == 'Successful'"
    >
      <div class="flex flex-col items-center">
        <div
          class="
            flex flex-row
            gap-4
            bg-green-300
            p-4
            my-2
            text-white
            rounded-md
          "
        >
          <svg
            class="h-8 w-8 text-green-600"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
          <p class="font-semibold text-green-600 my-auto">
            Félicitations, votre paiement a été effectué avec succès.
          </p>
        </div>
        <div
          class="
            my-5
            text-white
            p-3
            rounded-lg
            flex flex-col-reverse
            align-middle
          "
        >
          <button class="text-gray-500 underline" (click)="printReceipt()">
            Imprimer mon reçu<svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 mx-auto"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex flex-row justify-end px-10">
        <button
          class="total-amount text-white px-10 py-2 rounded-md my-4"
          (click)="goBackHome()"
        >
          Retour
        </button>
      </div>
      <!-- Receipt -->
      <div class="flex flex-col client-receipt">
        <div class="grid grid-cols-12 px-6 my-10">
          <div class="col-span-3">
            <img src="../../../assets/img/canalbox-logo.png" alt="" />
          </div>
          <div class="col-span-9 pl-40">
            <p class="bg-gray-100 my-5">
              Facture&nbsp;:&nbsp;{{ receiptNumber }}
            </p>
            <div class="flex flex-row gap-4">
              <p class="bg-gray-100">Référence partenaire :</p>
              <p class="text-red-500">{{ partnerReference }}</p>
            </div>
          </div>
        </div>
        <div class="px-3 my-5">
          <p class="uppercase bg-gray-100 font-bold px-4">
            coordonnées de l'abonné
          </p>
          <div class="my-16 px-12 personal-info">
            <div>
              NOM : <span>{{ lastName }}</span>
            </div>
            <div>
              PRÉNOM : <span>{{ firstName }}</span>
            </div>
            <div>
              ADRESSE : <span>{{ address }}</span>
            </div>
            <div>
              VILLE : <span>{{ city }}</span>
            </div>
            <div>
              TEL : <span>{{ phoneNumber }}</span>
            </div>
            <div>
              EMAIL : <span>{{ email }}</span>
            </div>
          </div>
        </div>
        <div class="my-2 px-3">
          <p class="uppercase bg-gray-100 font-bold px-4">
            informations de l'abonnemment
          </p>
          <div class="">
            <div class="flex flex-row justify-evenly my-10">
              <p class="uppercase">
                n° du réabonnement :
                <span class="text-red-500">{{ routerNumber }}</span>
              </p>
              <p class="uppercase">
                n° de référence transaction :
                <span class="text-red-500">{{ referenceNumber }}</span>
              </p>
            </div>
            <div class="flex flex-row justify-center">
              <div>
                <table>
                  <tr>
                    <th>Désignation</th>
                    <th>MONTANT&nbsp;TTC</th>
                  </tr>
                  <tr>
                    <td>{{ offerName }}</td>
                    <td>{{ offerPrice }}&nbsp;CFA</td>
                  </tr>
                  <tr>
                    <td>Total TTC</td>
                    <td class="font-bold">{{ offerPrice }} CFA</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex flex-row justify-evenly mx-40 mt-10 mb-10">
              <p>Mode de règlement :</p>
              <p class="font-bold">{{ paymentMethod }}</p>
            </div>
          </div>
        </div>
        <div class="text-center">
          <p>
            Toute demande via ce partenaire sera traitée du lundi au dimanche de
            07h à 20h.
          </p>
          <p class="text-red-600">
            Pour plus d'information, veuillez contacter le service client au
            1341.
          </p>
        </div>
      </div>

      <!-- End of Receipt -->
    </div>

    <!-- InProgress Refresh -->
    <div
      class="bg-white py-8"
      *ngIf="
        paymentProcessingStatus == 'InProgress' && checkStatusAttempts == 1
      "
    >
      <div class="xs:px-6">
        <div
          class="
            flex flex-row
            justify-center
            my-10
            bg-gray-300
            p-3
            rounded-md
            mx-44
            xs:mx-0
            my-5
          "
        >
          <div class="my-auto mx-5 xs:mx-0">
            <svg
              class="h-8 w-8 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="flex flex-col xs:pl-5">
            <p>Votre paiement est en cours de vérification.</p>
          </div>
        </div>
        <div class="text-center xs:mb-10">
          <button
            class="bg-blue-500 text-white p-2 rounded-md"
            (click)="doubleCheckStatus()"
          >
            Actualiser
          </button>
        </div>
      </div>

      <div class="flex flex-row justify-end px-10">
        <button
          class="total-amount text-white px-10 py-2 rounded-md my-4"
          (click)="goBackHome()"
        >
          Retour
        </button>
      </div>
    </div>

    <!-- InProgress Finally -->
    <div
      class="bg-white py-8"
      *ngIf="
        paymentProcessingStatus == 'InProgress' && checkStatusAttempts == 2
      "
    >
      <div class="xs:px-6">
        <div
          class="
            flex flex-row
            justify-center
            my-10
            bg-gray-300
            p-3
            rounded-md
            mx-44
            xs:mx-0
            my-5
          "
        >
          <div class="my-auto mx-5 xs:mx-0">
            <svg
              class="h-8 w-8 text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="flex flex-col xs:pl-5">
            <p>Cher client,</p>
            <p>
              Votre paiement a été interrompu ou est probablement en cours de
              vérification.
            </p>
            <p>
              Nous vous invitons à conserver la référence du paiement pour des
              cas de réclamation.
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-row justify-end px-10">
        <button
          class="total-amount text-white px-10 py-2 rounded-md my-4"
          (click)="goBackHome()"
        >
          Retour
        </button>
      </div>
    </div>

    <!-- Failed -->
    <div
      class="bg-white py-8 w-full"
      *ngIf="paymentProcessingStatus == 'Failed'"
    >
      <div class="flex flex-col items-center xl:mx-8 xs:m-4">
        <div
          class="
            flex flex-row
            gap-4
            bg-red-300
            p-6
            my-2
            text-red-600
            rounded-md
          "
        >
          <svg
            class="h-8 w-8 text-red-900 my-auto xs:w-24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path
              d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
            />
            <line x1="12" y1="9" x2="12" y2="13" />
            <line x1="12" y1="17" x2="12.01" y2="17" />
          </svg>
          <div>
            <p>
              Votre paiement a été
              <span class="text-red-900">interrompu</span> ou la transaction est
              <span class="text-red-900">annulée</span>.
            </p>
            <p>
              Pour effectuer le paiement, veuillez recommencer le processus de
              paiement ou changer le mode de paiement.
            </p>
          </div>
        </div>
        <div class="flex flex-row gap-6">
          <div class="my-5 bg-blue-400 text-white p-3 rounded-lg">
            <button (click)="changePaymentMethod()">
              Changer mode de paiement
            </button>
          </div>
          <div class="my-5 bg-blue-400 text-white py-3 px-8 rounded-lg">
            <button (click)="makeNewPayment()">Nouvelle demande</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Session Expired -->
    <div
      class="bg-white py-8 w-full"
      *ngIf="paymentProcessingStatus == 'Expired'"
      [ngClass]="{ 'py-52': paymentProcessingStatus == 'Expired' }"
    >
      <div class="flex flex-col items-center">
        <div class="flex flex-row bg-gray-200 gap-4 p-3">
          <div class="w-10 flex flex-col my-auto xs:w-20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="flex flex-col">
            <p>Cher Client,</p>
            <p>Nous vous informons que votre paiement a expiré.</p>
            <p>
              Pour effectuer le paiement, veuillez recommencer le processus de
              réabonnement.
            </p>
          </div>
        </div>
        <div class="my-5 bg-blue-500 text-white p-3 rounded-lg">
          <button (click)="goBackHome()">Recommencer le paiement</button>
        </div>
      </div>
    </div>
    <!-- Triggered when Pop-Up is blocked -->
    <div class="bg-white py-8 w-full" *ngIf="isPopUpBlocked">
      <div class="flex flex-col items-center">
        <div class="flex flex-row bg-gray-200 gap-4 p-3">
          <div class="w-10 flex flex-col my-auto xs:w-20">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>
          </div>
          <div class="flex flex-col">
            <p>Il semble que votre navigateur bloque la Pop-Up de paiement.</p>
          </div>
        </div>
        <div class="my-5 bg-blue-500 text-white p-3 rounded-lg">
          <button (click)="reloadPaymentPage()">
            Continuer le paiement ici
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal button -->
  <button
    class="hidden"
    (click)="ngxSmartModalService.getModal('changeNumOrMno').open()"
    id="modal-1"
  ></button>
  <button
    class="hidden"
    (click)="ngxSmartModalService.getModal('acceptPolicy').open()"
    id="modal-2"
  ></button>
</div>

<ngx-smart-modal #changeNumOrMno identifier="changeNumOrMno">
  <h1>Attention !</h1>
  <p>Votre numéro mobile money ne correponds pas aux choix de l’opérateur.</p>

  <div class="flex flex-row justify-around my-3">
    <button
      class="bg-gray-300 mx-4 rounded-lg p-2"
      (click)="changeNumOrMno.close()"
    >
      Changer le mode de paiement
    </button>
    <button class="bg-gray-300 mx-4 rounded-lg p-2" routerLink="/gva">
      Modifier mon numéro Mobile Money
    </button>
  </div>
</ngx-smart-modal>

<ngx-smart-modal #acceptPolicy identifier="acceptPolicy">
  <p>
    Acceptez les conditions d'utilisation en cochant la case puis procéder au
    paiement
  </p>
  <div class="flex flex-row justify-center my-3">
    <button
      class="bg-gray-300 mx-4 rounded-lg p-2"
      (click)="acceptPolicy.close()"
    >
      Fermer
    </button>
  </div>
</ngx-smart-modal>
