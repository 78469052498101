<div class="bg-white h-screen  text-2xl flex flex-col justify-center text-center gap-5">
  <p class="text-red-600">Votre paiement a été annulé !</p>
  <p>Vous serez redirigé dans  <countdown
    class="text-yellow-500"
    #delay
    [config]="{ leftTime: 5, format: 'm:s' }"
    (event)="handleEvent($event)"
  ></countdown></p>
  <button class="bg-green-500 text-white p-2 mx-40 rounded-md mt-5 xs:mx-20" (click)="closeWindow()">Fermer la Pop-up</button>
</div>
